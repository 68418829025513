.top-portfolio {
  margin: 2px;
  border-bottom: 2px solid rgb(28, 68, 28);
  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.port-div {
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-bottom: 2px solid rgb(28, 68, 28);
  padding-bottom: 10px;
  .title-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    text-decoration: underline;
    a {
      color: black;
    }
    h4 {
      padding-right: 30px;
    }
  }
  .port-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding-left: 80px;
      padding-right: 20px;
      width: 35%;
      li {
        text-align: left;
        width: 100%;
        font-size: 1.2rem;
        margin-top: 2px;
      }
    }
  }
}
.git-icon {
  width: 60%;
  padding-left: 20px;
  .git {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .port-div {
    display: flex;
    flex-direction: column;

    .port-layout {
      display: flex;
      flex-direction: column-reverse;
      ul {
        width: 80vw;
        padding: 0px;
        padding-left: 5px;
      }
      .git-icon {
        width: 80vw;
        padding: 0px;
        .git {
          width: 80vw;
          img {
            width: 100%;
            height: 100%;
            align-self: center;
          }
        }
      }
    }
  }
}
