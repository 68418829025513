@import "styling";
@import "nav";
@import "home";
@import "portfolio";
@import "about";

* {
  box-sizing: border-box;
  font-family: $body-font;
}
