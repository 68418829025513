.header-nav {
  display: block;
  background-color: rgb(3, 54, 29);

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    li {
      line-height: 1;
      font-size: 30px;
      font-weight: 500;
      display: inline;
      padding: 10px 15px 10px 15px;
      font-family: $body-font;
    }
    a {
      text-decoration: none;
      color: $background;
    }
    li:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
