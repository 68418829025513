body {
  background-color: rgb(3, 54, 29);
}
main {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 90vw;
  margin: 5vw;
  margin-top: 0vw;
  background-color: $background;
  border-radius: 5px;
  padding: 2px;
}
.blurb {
  font-size: 1.2rem;
}
.top-content {
  display: flex;
  padding: 10px;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 100%;
    width: 250px;
    height: 250px;
  }
  .right-top {
    margin-left: 20px;
    max-width: 40%;
  }
}
svg {
  height: 2em;
  width: 2em;
}
.skill-icons {
  display: flex;
  flex-direction: column;
}
.front-end {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.back-end {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.organisational {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.skill {
  display: flex;
  // width: 100px;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 0px;
  background-color: #c7eccb;
  border-radius: 5px;
  margin: 5px;
  max-width: 88vw;
  img {
    width: 100%;
  }
  p {
    padding: 0px;
    height: 10px;
  }
}

@media screen and (max-width: 600px) {
  .top-content {
    display: flex;
    flex-direction: column;

    .right-top {
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        width: 60vw;
        align-self: center;
        align-items: center;
      }
      p {
        margin-top: 5px;
      }
      .blurb {
        width: 80vw;
        align-self: center;
      }
    }
  }
}
