.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  max-width: 70vw;
  h4,
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .about-seg {
    display: flex;
    flex-direction: column;
    h4 {
      border-bottom: 2px solid rgb(28, 68, 28);
      border-top: 2px solid rgb(28, 68, 28);
    }
  }
}
